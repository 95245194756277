<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="searchType"
                :options="searchlist"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="searchKeyword"
                :disabled="!searchType"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="GetList()"
            >
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="p-1">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-users" /> รายการแนะนำเพื่อน
            </h2>
          </div>
          <div class="col d-flex justify-content-end">
            <router-link
              :to="{ path: `/Member-add` }"
              class="btn btn-gradient-primary"
            >
              <i class="fa fa-plus" /> สร้างผู้แนะนำใหม่
            </router-link>
          </div>
        </div>
      </div>
      <b-table
        hover
        show-empty
        responsive="sm"
        :items="items"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <span
                class="font-weight-bold"
              >({{ data.item.zean_percent }}%) <router-link :to="{ path: `invite-detail/${data.item.invite_code}/${data.item.username}` }">{{
                data.item.names
              }}  <i class="fad fa-pennant text-warning" /></router-link>
              </span>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
              @click="GoToEdit(data.item.username)"
            >
              <i class="fa fa-edit" />
            </b-button>
            <b-button
              variant="gradient-info"
              class="mr-1"
              size="sm"
              @click="GoToDetail(data.item.invite_code,data.item.username)"
            >
              เพื่อนที่แนะนำ
            </b-button>
          </div>
        </template>
        <template #cell(SumAll)="data">
          <span v-if="data.item.SumAll === 0">{{ data.item.SumAll }}</span>
          <b-spinner
            v-else-if="!data.item.SumAll "
            variant="primary"
            small
          />
          <span v-else>{{ data.item.SumAll }}</span>

        </template>
        <template #cell(SumDep)="data">
          <span v-if="data.item.SumDep === 0">{{ data.item.SumDep }}</span>
          <b-spinner
            v-else-if="!data.item.SumDep "
            variant="primary"
            small
          />
          <span v-else>{{ data.item.SumDep }}</span>

        </template>
        <template #cell(win_lose)="data">
          <span v-if="data.item.win_lose === 0">{{ Number(data.item.win_lose).toFixed(2) }}</span>
          <b-spinner
            v-else-if="!data.item.win_lose "
            variant="primary"
            small
          />
          <span v-else>{{ Number(data.item.win_lose).toFixed(2) }}</span>

        </template>

      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 pl-1 pb-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 pr-1 pt-1"
            @input="GetList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import {
  BButton, BTable, BFormSelect, BFormGroup, BPagination, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BTable,
    BFormSelect,
    BFormGroup,
    BPagination,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      searchType: null,
      searchKeyword: null,
      dateStart: null,
      dateEnd: null,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      UserData: JSON.parse(localStorage.getItem('userData')),
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'action', label: '' },
        { key: 'name', label: 'ผู้ใช้งาน' },
        { key: 'username', label: 'USERNAME' },
        { key: 'created_at', label: 'สมัครเมื่อ' },
        { key: 'SumAll', label: 'สมัครวันนี้' },
        { key: 'SumDep', label: 'สมัครและฝากวันนี้' },
        { key: 'win_lose', label: 'WIN / LOSE วันนี้' },
      ],
      items: [],
      userlist: [],
      searchlist: [
        { value: 1, text: 'ชื่อ' },
        { value: 2, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
      ],
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    GetList() {
      let index = 0
      const params = {
        searchType: this.searchType,
        searchKeyword: this.searchKeyword,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/index', params)
        .then(response => {
          this.userlist = response.data.data
          this.userlist.forEach(items => {
            this.userlist[index].SumAll = null
            this.userlist[index].SumDep = null
            this.userlist[index].win_lose = null
            this.getregis_dep(items.invite_code, index)
            index += 1
          })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getregis_dep(invite, index) {
      const params = {
        invite_code: invite,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/getregis_dep', params)
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.userlist[index].SumAll = response.data.SumAll
          this.userlist[index].SumDep = response.data.SumDep
          this.userlist[index].win_lose = response.data.sumwl
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    GoToDetail(id, username) {
      this.$router.push({ name: 'invite-detail-byadmin', params: { id, username } })
    },
    GoToEdit(id) {
      this.$router.push({ name: 'Deposit-Withdraw', params: { id } })
    },
  },
}
</script>

<style scoped>
.search-1 {
  padding: 1rem;
  background: #000;
}
thead th {
  background: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
